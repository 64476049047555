/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Logistics from 'views/Logistics';
import Main from 'layouts/Main';
import { graphql } from 'gatsby';

const IndexPage = ({ data }) => {
  return (
    <div className="container">
      <Main>
        <Logistics data={data} />
      </Main>
    </div>
  );
};

export const query = graphql`
  {
    allContentfulNewsletter(
      sort: { fields: publishDate, order: DESC }
      limit: 2
    ) {
      nodes {
        title
        subtitle
        tags
        id
        publishDate
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allContentfulResource(sort: { fields: createdAt, order: DESC }, limit: 5) {
      nodes {
        title
        id
        createdAt(locale: "")
      }
    }
  }
`;

export default IndexPage;
