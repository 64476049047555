import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Button, useMediaQuery, colors } from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { Link } from 'gatsby';

const Story = props => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Who we are"
        subtitle="Our Mission at Helen Ge CPA Professional Corporation is to provide exceptional client services with integrity and personal attention. We are passionate about our business and profession. We strive to exceed expectations."
        data-aos="fade-up"
      />
      <Grid item container justify="center" xs={12} data-aos={'fade-up'}>
        <Button variant="contained" color="primary">
          <Link to="/about-us" style={{ color: 'inherit' }}>
            Read our story
          </Link>
        </Button>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
