export const services = [
  {
    icon: 'fas fa-briefcase',
    title: 'Corporate Tax',
    description: [
      'Financial statements and tax returns preparation',
      'Strategic tax planning',
      'GST, HST, Payroll, WSIB',
      'R&D tax credit assistance',
      'Representation to tax authorities',
    ],
  },
  {
    icon: 'fas fa-user-tie',
    title: 'Personal Tax',
    description: [
      'Personal income tax return preparation',
      'Estate tax returns preparation and planning',
      'Tax planning for individual',
      'Representation to tax authorities',
    ],
  },

  {
    icon: 'far fa-lightbulb',
    title: 'Consulting',
    description: [
      'Financial budget and projection',
      'Accounting software setup and training',
      'Business restructure and internal financial management',
      'Buy and sell business consultation',
    ],
  },
];

export const features = [
  {
    icon: 'far fa-comment',
    title: `Ensuring clear communication`,
    subtitle:
      'We keep you in the loop every step of the way. You can rely on a transparent, comprehensible flow of information.',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Making the most of technology',
    subtitle:
      'Time is precious. We guarantee a high degree of efficiency and a premium level of service by using the right tools.',
  },
  {
    icon: 'far fa-heart',
    title: 'Providing excellent customer service',
    subtitle:
      'We are passionate about what we do and we take pride in achieving the personalised results that you have in mind.',
  },
];

export const team = [
  'Our sign up is dead simple. We only require your basic company information',
  'We support bulk uploading via SQL, integrations with most data storage products',
  "Simply select where you'd like to transfer your data ",
];

export const reviews = [
  {
    authorName: 'Johanna Roach',
    authorOccupation: 'Knowledge Bridge Inc.',
    feedback:
      'We have been working with Helen Ge for over 17 years. Her personal, honest, and proactive approach is contagious. She has provided us with expert solid financial planning advice, effectively taking on the role of an in‐house financial director to a business our size has been invaluable. Hands down, we recommend Helen Ge every time someone is looking for accountant because they cannot go wrong with her excellent work.',
  },
  {
    authorName: 'Karen Breeck',
    authorOccupation: 'MD',
    feedback:
      "I'm still grateful almost 10 years later, that when I needed to find an accountant a friend introduced me to Helen. I've learned so much from Helen and now I introduce her without hesitation to all of my friends.",
  },
  {
    authorName: 'Mark Arnold',
    authorOccupation: 'President of J Mark consultants Inc.',
    feedback:
      'As Principal Consultant for J Mark Consultants once I launched my own company My Financial Advisor strongly recommended that I consider Helen Ge CPA Professional Corporation to support me in managing my accounts and taxes. It was one of the best decisions I ever made. Helen has been tremendously supportive and patient as my business grew it was a challenge for me to stay on top of everything. Working with Helen and her firm brought peace of mind knowing that payroll and the taxes were being so well managed.',
  },
  {
    authorName: 'Christopher Chiu',
    authorOccupation: 'Director of Overunity Corp.',
    feedback:
      'Hi Helen, Just wanted to send a note of thanks to you and your staff for handling my personal and corporate taxes so professionally. Evan and Vivian have been a pleasure to work with and they both have been very patient in explaining what was needed and in answering my questions. I am very please with the level of service I am receiving and I look forward to working with you and your staff going forward.',
  },
  {
    authorName: 'Crystal Li',
    authorOccupation: 'Co-op student at Helen Ge CGA',
    feedback:
      'I’ve come out of these three months armed new software programs, with a much keener sense for accounting applications in everyday life, and most importantly, newfound confidence in the value of classroom content as well as a much better sense of what accounting is, and can become. For that, I’m truly glad to have been a part of this team. Thank you.',
  },
];

export const news = [
  {
    cover: {
      src:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news1.jpg',
      srcSet:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news1@2x.jpg 2x',
    },
    tag: 'delivery',
    title: 'Optimize the schedules of thousands of trains in minutes.',
    date: '12 June, 2020',
  },
  {
    cover: {
      src:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news2.jpg',
      srcSet:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news2@2x.jpg 2x',
    },
    tag: 'Main stream',
    title: 'Optimize the schedules of thousands of trains in minutes.',
    date: '12 June, 2020',
  },
  {
    cover: {
      src:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news3.jpg',
      srcSet:
        'https://assets.maccarianagency.com/the-front/photos/logistics/news3@2x.jpg 2x',
    },
    tag: 'Trucks',
    title: 'Optimize the schedules of thousands of trains in minutes.',
    date: '12 June, 2020',
  },
];
