import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
  },
  card: {
    display: 'flex',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardHighlighted: {
    background: theme.palette.primary.main,
  },
  image: {
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 170,
    },
    [theme.breakpoints.up('sm')]: {
      height: 180,
      width: 180,
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  news: {
    [theme.breakpoints.down('md')]: {
      order: '1',
    },
  },
  resources: { [theme.breakpoints.down('md')]: { order: '3' } },
  allNews: {
    [theme.breakpoints.down('md')]: {
      order: '2',
    },
  },
}));

const News = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const {
    allContentfulNewsletter: { nodes: news },
    allContentfulResource: { nodes: resources },
  } = data;

  return (
    <div className={className} {...rest}>
      <SectionHeader title="Our latest news" data-aos="fade-up" />
      <Grid container spacing={isMd ? 4 : 4}>
        <Grid item xs={12} md={8} className={classes.news}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            {news.map(item => (
              <Grid item xs={12} key={item.id} data-aos="fade-up">
                <Card className={classes.card}>
                  <CardMedia>
                    <Image
                      {...item.cover.fluid}
                      alt={item.title}
                      className={classes.image}
                      lazyProps={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </CardMedia>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.tags}>
                      {item.tags.map((item, index) => (
                        <Typography
                          variant="caption"
                          className={classes.tag}
                          key={index}
                        >
                          {item}
                        </Typography>
                      ))}
                    </div>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      component={Link}
                      to={`/blog-newsroom/${item.title.split(' ').join('-')}`}
                    >
                      {item.title.length > 40
                        ? item.title.slice(0, 40) + '...'
                        : item.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component={Link}
                      to={`/blog-newsroom/${item.title.split(' ').join('-')}`}
                    >
                      {item.subtitle.length > 120
                        ? item.subtitle.slice(0, 120) + '...'
                        : item.subtitle}
                    </Typography>
                    <Typography
                      align="right"
                      component={Link}
                      color="primary"
                      to={`/blog-newsroom/${item.title.split(' ').join('-')}`}
                    >
                      Read full article
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.resources}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow className={classes.cardHighlighted}>
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.textWhite}
                  gutterBottom
                >
                  Resources
                </Typography>
                <Box py={2} display="flex" flexDirection="column">
                  {resources.map(item => {
                    return (
                      <Typography
                        component={Link}
                        to={`/resources/${item.title.split(' ').join('-')}`}
                        key={item.id}
                        className={classes.textWhite}
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                    );
                  })}
                </Box>
                <Button variant="contained" color="textSecondary">
                  <Link to="/resources" style={{ color: 'inherit' }}>
                    All Resources
                  </Link>
                </Button>
              </CardBase>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          data-aos={'fade-up'}
          className={classes.allNews}
        >
          <Button variant="contained" color="primary">
            <Link to="/blog-newsroom" style={{ color: 'inherit' }}>
              See more Newsletters
            </Link>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

News.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  //data: PropTypes.array.isRequired,
};

export default News;
