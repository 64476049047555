import React from 'react';
import { Grid, colors, Typography, Box } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

const Features = props => {
  const { data, className, ...rest } = props;

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <SectionHeader title="Our Services" fadeUp />
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined" withShadow>
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    size="medium"
                    color={colors.indigo}
                  />
                }
                title={item.title}
              />
              <Box px={2} pt={2}>
                <ul>
                  {item.description.map((item, index) => {
                    return (
                      <Typography
                        key={index}
                        component="li"
                        gutterBottom
                        variant="subtitle2"
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </ul>
              </Box>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Features;
