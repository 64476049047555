import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import { Features, Hero, Story, News, Reviews } from './components';

import { services, reviews } from './data';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  featuresSection: {
    backgroundSize: 'contain',
  },
  integrationsSection: {
    background: '#0c133e',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  reviewSection: {
    background: theme.palette.primary.light,
  },
  aboutSection: {
    background: '#0c133e',
  },
}));

const Logistics = ({ data }) => {
  const classes = useStyles();

  return (
    <Box mt={8}>
      <Hero />
      <Section>
        <Story />
      </Section>
      <SectionAlternate className={classes.featuresSection}>
        <Features data={services} />
      </SectionAlternate>

      <SectionAlternate>
        <News data={data} />
      </SectionAlternate>
      <SectionAlternate>
        <Reviews data={reviews} />
      </SectionAlternate>
    </Box>
  );
};

export default Logistics;
